<template>

  <div v-if="restaurant">

    <div
      style="width: 100%; position: relative;"
      :style="{ 'height': isMobile ? '100px' : '300px' }"
      v-rellax="{ speed: -6 }"
      v-if="restaurant.backgroundImage"
    >
      <img v-lazy="restaurant.backgroundImage.url" class="p-img">
      <div class="img-gradient"></div>
    </div>

    <div class="container p-4" :style="{ 'top': restaurant.backgroundImage ? '-100px' : '0px' }">

      <div class="columns is-reversed-mobile mt-4">

        <div class="column" v-rellax="{ speed: 0, zindex: 4 }">

          <p class="is-size-4
                    is-size-5-mobile
                    has-text-weight-semibold
                    has-text-centered-mobile"
             :class="{
               'has-text-grey-darker': isMobile,
               'has-text-white': !isMobile && restaurant.backgroundImage,
             }">
            {{ restaurant.name }}
          </p>

          <div v-if="restaurant" class="mb-5 mt-6">
            <div>
              <p class="has-text-justified has-text-grey-darker is-size-6-mobile">
                {{ isDescriptionFullyDisplayed ? (
                    restaurant.description
                  ) : (
                    truncateString(restaurant.description)
                  )
                }}
              </p>
            </div>
            <div v-if="restaurant.description.length > 150"
                class="has-text-grey-dark
                       is-clickable
                       is-flex
                       is-justify-content-flex-end
                       is-align-items-center
                       mt-1"
                @click="isDescriptionFullyDisplayed = !isDescriptionFullyDisplayed">
              <b-icon :icon="isDescriptionFullyDisplayed ? 'chevron-up' : 'chevron-down'"
                      class="mr-1" />
              <span>{{ isDescriptionFullyDisplayed ? 'voir moins' : 'voir plus' }}</span>
            </div>
          </div>

          <div class="is-flex
                      is-justify-content-space-between
                      is-align-items-center
                      is-clickable"
              @click="isRestaurantInfosDialogOpen = true">

            <div  class="is-flex is-align-items-center">
              <b-icon icon='storefront-outline'
                      class="mr-3 has-text-grey" />
              <div>
                <p class="mb-1">Informations restaurant</p>
                <span class="has-text-secondary">Allergènes et plus</span>
              </div>
            </div>
          </div>

          <div class="is-flex is-justify-content-flex-end mt-5"
               v-if="restaurant.isCovidFormActivated">
            <b-button type="is-secondary"
                      outlined
                      size="default"
                      :expanded="isMobile"
                      @click="isCovidFormDisplayed = true">
              <b-icon icon="hand-wash"
                      :size="isMobile ? 'is-small' : ''"
                      class="mr-3" />
                Formulaire de rappel Covid 19
            </b-button>
          </div>

        </div>

        <div class="column
                    is-one-third
                    is-flex
                    is-flex-direction-column
                    is-justify-content-flex-start
                    is-align-items-center"
             v-rellax="{ speed: 6, zindex: 5 }">

          <div class="logo_container" style="min-height: 60px;">
            <b-image  alt="restaurant logo"
                      :src="restaurant.logo.url"
                      lazy
                      rounded
                      v-if="restaurant.logo"
                      class="m-0">
              <template #placeholder>
                <b-skeleton
                  class="skeleton-placeholder"
                  height="100%"
                  circle
                />
              </template>
            </b-image>
          </div>

          <div>
            <ul class="is-size-6 has-text-grey m-0 mt-4 px-4"
                style="list-style-type: none;">
              <li v-if="restaurant && restaurant.contactInfos.address.label">
                <div class="is-flex is-align-items-center">
                  <b-icon custom-size="mdi-24px"
                          icon='google-maps'
                          class="mr-2 has-text-link" />
                  <a :href="`http://maps.google.com/?q=${restaurant.contactInfos.address.label}`"
                    target="_blank">
                    {{ restaurant.contactInfos.address.label }}
                  </a>
                </div>
              </li>
              <li v-if="restaurant.businessHours.activated" class="mt-3">
                <div class="is-flex is-align-items-center">
                  <b-icon custom-size="mdi-24px" icon='clock-outline' class="mr-2" />
                  <span>{{ businessHours }}</span>
                </div>
              </li>
              <li class="has-text-secondary has-text-weight-medium mt-3"
                  v-if="restaurant.isTakeawayActivated">
                <div class="is-flex is-align-items-center">
                  <b-icon custom-size="mdi-24px" icon='check' class="mr-2" />
                  <span>Vente à emporter</span>
                </div>
              </li>
            </ul>
          </div>

        </div>

      </div>

      <div>

        <div class="is-flex
                    is-align-items-center
                    mt-5 mb-5">

          <b-dropdown v-model="selectedAllergens"
                      multiple
                      aria-role="list"
                      scrollable
                      :mobile-modal="false"
                      :close-on-click="true"
                      @change="$refs.allergensDropdown.toggle()"
                      ref="allergensDropdown">

            <template #trigger>
              <b-button :type="selectedAllergens.length ? 'is-warning' : 'is-primary is-light'"
                        icon-right="menu-down"
                        rounded>
                Filtre allergènes
              </b-button>
            </template>

            <b-dropdown-item v-for="allergen in allergens"
                             :key="allergen._id"
                             :value="allergen.label"
                             aria-role="listitem">
              <span>{{ allergen.label }}</span>
            </b-dropdown-item>

          </b-dropdown>

          <b-button type="is-light"
                    icon-left="cached"
                    class="ml-3"
                    v-if="selectedAllergens.length > 1"
                    @click="selectedAllergens = []" />

        </div>

        <div v-if="selectedAllergens.length" class="mb-5">
          <b-taglist>
            <b-tag v-for="allergen in selectedAllergens"
                   :key="allergen._id"
                   class="m-0 mr-3"
                   attached
                   aria-close-label="Remove allergen filter"
                   type="is-dark"
                   closable
                   @close="selectedAllergens = selectedAllergens.filter(a => a !== allergen)">
              {{ allergen }}
            </b-tag>
          </b-taglist>
        </div>

        <div class="mb-2">
          <p class="has-text-grey is-size-6">Menus</p>
        </div>

        <div class="menus_nav-bar scroll-y pt-2 pb-2">
          <a v-for="menu in menus"
             :key="menu._id"
             :href="`#${menu._id}`"
             :ref="`ref-menu-link-${menu._id}`"
             @click="onClickMenuLink(menu._id)"
             v-smooth-scroll
             :class="activeMenu === menu._id && 'has-text-white'">
            <b-tag class="is-clickable mr-4 p-5"
                  size="is-medium"
                  :type="activeMenu === menu._id ? 'is-secondary' : ''">
              {{ menu.name }}
            </b-tag>
          </a>
        </div>

        <app-restaurant-event-banner v-if="restaurant.isEventBannerActivated"
                                     :message="restaurant.eventBannerDescription"
                                     type="is-warning is-light"
                                     class="mt-4 column is-two-thirds" />

        <div v-for="menu in menus"
             :key="menu._id"
             :id="`${menu._id}`"
             class="mb-5 mt-5"
             :ref="`ref-menu-section`"
             :data-menuid="menu._id">

          <p class="is-size-5 has-text-weight-semibold scroll-menu">
            {{ menu.name }}
          </p>

          <!-- If menu not digital display files list -->
          <app-menu-files-list v-if="!menu.digital" :menu="menu" />

          <!-- Else display categories and cards -->
          <div v-else
               v-for="category in menu.meals.filter((m) => m.published)"
               :key="category._id">

            <p class="has-text-grey is-size-6">
              {{ category.name }}
            </p>

            <div class="card-grid pt-4 pb-4">

              <app-dish-card v-for="dish in category.dishes.filter((d) => d.published)"
                             :key="dish._id"
                             :dish="dish"
                             @card-click="displayDishCard($event)" />

            </div>

          </div>

        </div>

      </div>

      <b-modal v-model="isCovidFormDisplayed"
               has-modal-card
               trap-focus
               :can-cancel="['x']"
               :destroy-on-hide="true"
               aria-role="dialog"
               aria-label="Add covid contact dialog"
               aria-modal
               :full-screen="isMobile"
               scroll="keep">
        <template #default="props">
          <app-add-covid-contact-form v-bind="{
                                        restaurant: { ...restaurant },
                                        confirmation: true,
                                        isFullScreen: isMobile,
                                      }"
                                      @close="props.close(); openWelcomeNotification();" />
        </template>
      </b-modal>

      <b-modal v-model="isDishDialogOpen"
               has-modal-card
               trap-focus
               :destroy-on-hide="true"
               :can-cancel="['x']"
               aria-role="dialog"
               aria-label="Dish dialog"
               aria-modal
               :full-screen="isMobile">
        <template #default="props">
          <app-dish-dialog v-bind="{
                            dish: dishToDisplay,
                            isFullScreen: isMobile,
                          }"
                          @close="props.close();" />
        </template>
      </b-modal>

      <b-modal v-model="isRestaurantInfosDialogOpen"
               has-modal-card
               trap-focus
               :destroy-on-hide="true"
               :can-cancel="['x']"
               aria-role="dialog"
               aria-label="Restaurant infos dialog"
               aria-modal
               :full-screen="isMobile">
        <template #default="props">
          <app-restaurant-infos-dialog v-bind="{
                                        restaurant: restaurant,
                                        isFullScreen: isMobile,
                                      }"
                                      @close="props.close();" />
        </template>
      </b-modal>

    </div>

  </div>

</template>

<script>
import moment from 'moment';
import truncate from 'lodash.truncate';
import AddCovidContactForm from '@/components/shared/AddCovidContactForm.vue';
import DishCardDialog from '@/components/client/DishCardDialog.vue';
import RestaurantInfosDialog from '@/components/client/RestaurantInfosDialog.vue';
import MenuFilesList from '@/components/client/MenuFilesList.vue';
import RestaurantEventBanner from '@/components/client/RestaurantEventBanner.vue';
import DishCards from '@/components/shared/DishCard.vue';

export default {
  components: {
    'app-add-covid-contact-form': AddCovidContactForm,
    'app-dish-dialog': DishCardDialog,
    'app-restaurant-infos-dialog': RestaurantInfosDialog,
    'app-menu-files-list': MenuFilesList,
    'app-restaurant-event-banner': RestaurantEventBanner,
    'app-dish-card': DishCards,
  },
  data: () => ({
    loading: true,
    isCovidFormDisplayed: false,
    dishToDisplay: null,
    isDishDialogOpen: false,
    activeMenu: null,
    windowInnerWidth: window.innerWidth,
    scrollPosition: null,
    isDescriptionFullyDisplayed: false,
    isRestaurantInfosDialogOpen: false,
    selectedAllergens: [],
    allergens: [],
    loadingAllergenList: false,
    error: null,
  }),
  computed: {
    isMobile() {
      // window.matchMedia('screen and (max-width: 768px)').matches
      return this.windowInnerWidth < 768;
    },
    restaurant() {
      // eslint-disable-next-line dot-notation
      return this.$store.getters['restaurantModule/restaurant'];
    },
    hasCovidFormAlreadyBeenDisplayed() {
      // eslint-disable-next-line dot-notation
      return this.$store.getters.hasCovidFormAlreadyBeenDisplayed;
    },
    businessHours() {
      const today = moment(new Date()).format('ddd').slice(0, -1).toUpperCase();
      const days = this.restaurant?.businessHours?.days;
      const businessHour = days.find((d) => d.label === today);

      if (businessHour.open && businessHour.hours.length) {
        let str = 'Ouvert de ';

        businessHour.hours.forEach((h, index) => {
          const start = moment(h.start_time).format('HH:mm');
          const end = moment(h.end_time).format('HH:mm');

          if (index === (businessHour.hours.length - 1)) {
            str += `${start} à ${end}`;
          } else {
            str += `${start} à ${end} -`;
          }
        });

        return str;
      }
      if (businessHour.open) {
        return 'ouvert';
      }
      return 'Fermé';
    },
    menus() {
      // filter only published menu
      let filteredMenus = this.restaurant?.menus.filter((m) => m.published) || [];

      // filter only published menus dishes by if not in selectedAllergens
      if (filteredMenus.length) {
        filteredMenus = filteredMenus.map((menu) => {
          const meals = menu.meals.map((meal) => {
            const dishes = meal.dishes.filter((d) => (
              !d.allergens.some((a) => this.selectedAllergens.includes(a.label))
            ));

            return { ...meal, dishes };
          });
          return { ...menu, meals };
        });
      }

      return filteredMenus;
    },
  },
  created() {
    this.fetchRestaurant();
    this.fetchAllergens();
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    window.addEventListener('scroll', this.updateScroll);

    // setTimeout(async () => {
    //   this.rellax = await new Rellax('.rellax');
    // }, 100);
  },
  methods: {
    getFilteredAllergens(text) {
      this.filteredAllergens = this.allergens.filter((option) => (
        option.label
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
      ));
    },
    async fetchAllergens() {
      this.loadingAllergenList = true;
      try {
        const res = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/allergens`,
        );

        this.allergens = res.data;
      } catch (error) {
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Impossible de récupérer la liste des allergènes.',
        });
      } finally { this.loadingAllergenList = false; }
    },
    truncateString(str, n = 150) {
      return truncate(str, {
        length: n,
        separator: /,? +/,
      });
    },
    onClickMenuLink(menuId) {
      setTimeout(() => { this.activeMenu = menuId; }, 700);
    },
    onResize() {
      this.windowInnerWidth = window.innerWidth;
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
      this.checkActiveMenu();
    },
    checkActiveMenu() {
      const refs = this.$refs['ref-menu-section'];

      if (refs) {
        refs.forEach((el) => {
          const elTop = el.getBoundingClientRect().top;

          if (elTop < 130 && elTop > 40) {
            // set active menu
            this.activeMenu = el.dataset.menuid;
            // scroll horizontally in menu nav bar to active menu in mobile
            this.scrollToMenuNavActiveItem(el.dataset.menuid);
          }
        });
      }
    },
    scrollToMenuNavActiveItem(menuId) {
      const el = this.$refs[`ref-menu-link-${menuId}`][0];
      const elLeft = el.offsetLeft;
      const elParentLeft = el.parentNode.offsetLeft;

      // check if element not in view
      if (elLeft >= elParentLeft + el.parentNode.scrollLeft) {
        el.parentNode.scrollTo({ left: elLeft - elParentLeft, behavior: 'smooth' });
      } else if (elLeft <= el.parentNode.offsetLeft + el.parentNode.scrollLeft) {
        el.parentNode.scrollTo({ left: el.offsetLeft - el.parentNode.offsetLeft, behavior: 'smooth' });
      }
    },
    async fetchRestaurant() {
      const loadingComponent = this.$buefy.loading.open({
        container: null,
      });
      const { restaurantId } = this.$route.params;

      try {
        const res = await this.fetchRestaurantById(restaurantId);
        const restaurant = res.data;

        this.isCovidFormDisplayed = restaurant.isCovidFormActivated
                                      && !this.hasCovidFormAlreadyBeenDisplayed;
        this.error = null;
        this.$store.dispatch('restaurantModule/updateSelectedRestaurant', { restaurant });

        const menuId = String(this.$route.hash.slice(1));

        if (menuId && this.isMongooseObjectIdString(menuId)) {
          // Throw an error without setTimeout
          setTimeout(() => {
            const refs = this.$refs['ref-menu-section'];

            refs.forEach((el) => {
              if (el.dataset.menuid === menuId) {
                this.$smoothScroll({
                  scrollTo: el,
                  hash: `#${menuId}`,
                });
              }
            });
          }, 1);
        }
      } catch (error) {
        this.$router.push({ name: 'PageNotFound' });
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Impossible de récupérer les données du restaurant.',
        });
      } finally { loadingComponent.close(); }
    },
    fetchRestaurantById(id) {
      return this.$http.get(
        `${process.env.VUE_APP_API_URL}/restaurants/${id}`,
      );
    },
    isMongooseObjectIdString(str) {
      if (typeof str !== 'string') {
        return false;
      }
      return str.match(/^[a-f\d]{24}$/i);
    },
    displayDishCard(dish) {
      this.dishToDisplay = dish;
      this.isDishDialogOpen = true;
    },
    formatedPrice(value) {
      if (typeof value !== 'number') {
        return value;
      }
      const formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(value);
    },
    getFormatedMultiPrice(prices) {
      const fPrices = [...prices].map((p) => {
        const v = p.value && this.formatedPrice(p.value);

        return `${p.label} (${v})`;
      });

      return fPrices.join(' - ');
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('scroll', this.updateScroll);
  },
};
</script>

<style lang="scss" scoped>
  .img-gradient {
    width: 100%;
    height: 125px;
    position: absolute;
    bottom: 0;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.30));
  }
  .p-img {
    filter: grayscale(40%);
    object-fit: cover;
    border: none;
    display: block;
    height: 100%;
    width: 100%;
  }
  .logo_container {
    max-width: 150px;
  }
  .menus_nav-bar {
    position: -webkit-sticky;
    position: sticky;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    top: 0px;
    z-index: 10;
    background-color: rgb(252, 253, 253);
  }
  .scroll-y {
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  ::-webkit-scrollbar {
    display: none;
    width: 0px;
    background: transparent;
  }
  .card-grid {
    display: grid;
    max-width: 815px;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr;
  }
  ::v-deep .modal-close {
    display: none !important;
  }
  ::v-deep label {
    font-weight: 400 !important;
  }
  ::v-deep .b-skeleton {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media(max-width: $tablet) {
    .columns.is-reversed-mobile {
      display: flex;
      flex-direction: column-reverse;
    }
    .card-grid {
      grid-gap: 10px;
      grid-template-columns: 1fr;
    }
    .logo_container {
      max-width: 125px;
    }
  }
</style>