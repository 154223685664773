<template>
  <div class="modal-card" :style="!isFullScreen && { 'max-width': '560px' }">

    <header class="modal-card-head">
      <p class="modal-card-title m-0">Informations de contact</p>
      <button type="button"
              class="delete"
              @click="$emit('close')"/>
    </header>

    <section class="modal-card-body">

      <form class="form"
            @submit.prevent="sendContactInfos"
            v-if="!showSuccessMessage">

        <div class="is-flex
                    is-align-items-center
                    mb-3">

          <div v-if="restaurant && restaurant.logo" style="max-width: 20%;">
            <b-image :src="restaurant.logo.url"
                     alt="restaurant logo"
                     lazy
                     rounded
                     class="m-0" />
          </div>

          <div class="p-5">

            <p class="has-text-justified has-text-grey is-size-6">
              Bienvenue chez
              <span class="has-text-weight-semibold">
                {{ restaurant && restaurant.name }}
              </span>
              et merci de votre soutien pour faire face à la
              <span class="has-text-weight-semibold">Covid 19</span>
              !
            </p>

          </div>

        </div>

        <b-field label="Nom *">
          <b-input v-model="form.lastname"
                   placeholder="Nom"
                   name="name"
                   ref="nameInput" />
        </b-field>

        <phone-number-input @isvalid="isTelValid = $event"
                            @update-phone-number="form.phoneNumber = $event"></phone-number-input>

        <div class="is-flex is-justify-content-flex-end mt-4 mb-2">

          <div @click="areExtraInfosDisplayed = !areExtraInfosDisplayed"
               class="is-flex is-align-items-center"
               style="cursor: pointer;">
            <b-icon :icon="!areExtraInfosDisplayed ? 'plus-circle' : 'minus-circle'"
                    type="is-secondary"
                    class="mr-2"/>
            Informations complémentaires
          </div>

        </div>

        <template v-if="areExtraInfosDisplayed">

          <b-field label="Prénom">
            <b-input v-model="form.firstname" placeholder="Prénom"/>
          </b-field>

          <b-field label="Email"
                   :type="!isValidEmail ? 'is-danger' : ''"
                   :message="!isValidEmail ? 'Email non valide' : ''">
            <b-input type="email" v-model="form.email" placeholder="Email" />
          </b-field>

          <div>

            <b-field label="Nombre de personne">
              <b-numberinput min="1" v-model="form.quantity" />
            </b-field>

          </div>

        </template>

        <p class="is-size-7 has-text-right has-text-grey mt-5">* Champs requis</p>

        <div class="mt-6">

          <b-collapse class="card"
                      animation="slide"
                      :open="false"
                      aria-id="info1">
            <template #trigger="props">
              <div class="card-header" role="button" aria-controls="info1">
                <p class="card-header-title is-size-7 mb-0">
                  <b-icon icon="hand-wash"
                          type="is-primary"
                          class="mr-3" />
                  Pourquoi nous donner ces informations ?
                </p>
                <a class="card-header-icon">
                  <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
              </div>
            </template>
            <div class="card-content">
              <div class="content has-text-justified">
                <p>Formulaire de rappel COVID-19.</p>
                <p>
                  Vos coordonnées <span class="has-text-weight-semibold">sont collectées</span> pour
                  faciliter la recherche des
                  <span class="has-text-weight-semibold">« cas contacts »</span>.
                </p>
                <p>
                  Notre établissement est soumis au respect d’un protocole sanitaire spécifique,
                  qui prévoit le recueil d’informations vous concernant,
                  dans le cadre de la lutte contre l’épidémie de COVID-19.
                </p>
              </div>
            </div>
          </b-collapse>

          <b-collapse class="card"
                      animation="slide"
                      :open="false"
                      aria-id="info2">
            <template #trigger="props">
              <div class="card-header" role="button" aria-controls="info2">
                <p class="card-header-title is-size-7 mb-0">
                  <b-icon icon="information-outline"
                          type="is-primary"
                          class="mr-3" />
                  Informations relatives aux stockage des données
                </p>
                <a class="card-header-icon">
                  <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
              </div>
            </template>
            <div class="card-content">
              <div class="content has-text-justified">
                <p>
                  Les informations recueillies sur ce formulaire sont enregistrées
                  et utilisées uniquement par notre établissement :
                  <span class="has-text-weight-semibold">{{ restaurant && restaurant.name }}.</span>
                </p>
                <p>
                  Conformément aux obligations prévues dans le protocole sanitaire
                  défini par arrêté préfectoral,
                  vos données seront uniquement utilisées pour faciliter la recherche
                  des « cas contacts » par les autorités sanitaires,
                  et ne seront pas réutilisées à d’autres fins.
                  En cas de contamination de l’un des clients au moment de votre présence,
                  ces informations pourront être communiquées aux autorités sanitaires compétentes
                  (agents des CPAM,
                  de l’assurance maladie et/ou de l’agence régionale de santé),
                  afin de vous contacter et de vous indiquer le protocole sanitaire à suivre.
                </p>
                <p>
                  Vos informations seront conservées pendant <b>14 jours à compter de leur collecte,
                  et seront supprimées à l'issue de ce délai</b>.
                </p>
                <p>
                  Vous pouvez accéder aux données vous concernant,
                  les rectifier ou exercer votre droit à la limitation du traitement de vos données.
                </p>
                <p>
                  Pour exercer ces droits ou pour toute question sur le traitement de vos données,
                  vous pouvez contacter à l'adresse suivante:
                  <span class="has-text-weight-semibold">
                    {{ restaurant && restaurant.contactInfos.address.label }}
                  </span>.
                </p>
                <p>
                  Pour toute demande concernant les données, contacter
                  <span>
                    <a href="mailto:hello@cookie.menu?subject=Informations%20données%20covid-19">
                      hello@cookie.menu
                    </a>
                  </span>.
                </p>
                <p>
                  Si vous estimez, après nous avoir contactés,
                  que vos droits Informatique et Libertés ne sont pas respectés,
                  vous pouvez adresser une réclamation à la CNIL.
                </p>
                <a href="https://solidarites-sante.gouv.fr/actualites/presse/communiques-de-presse/article/renforcement-protocole-sanitaire-restaurants-zones-d-alerte-maximale">
                  https://solidarites-sante.gouv.fr
                </a>
              </div>
            </div>
          </b-collapse>

        </div>

      </form>

      <div success v-if="showSuccessMessage">

        <b-message type="is-success" has-icon>
          <p class="has-text-weight-semibold">Contact enregistré !</p>
        </b-message>

        <p>
          Le restaurant
          <span class="has-text-weight-semibold">{{ restaurant.name || 'Le restaurant' }}</span>
          vous remercie de votre compréhension.
        </p>
        <p>
          Vous pouvez présenter ce message comme justificatif à votre restaurateur.
        </p>
        <p>Bon appétit !</p>

      </div>

    </section>

    <footer v-if="!showSuccessMessage" class="modal-card-foot is-flex is-justify-content-flex-end">
      <b-button label="Annuler" @click="$emit('close')" />
      <b-button label="Envoyer"
                type="is-primary"
                @click="recaptcha"
                :disabled="!isFormValid"
                icon-left="send"
                :loading="loading" />
    </footer>

  </div>
</template>

<script>
import PhoneNumberInput from '@/components/PhoneNumberInput.vue';
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

export default {
  props: {
    displayed: {
      type: Boolean,
      default: () => false,
    },
    restaurant: {
      type: Object,
    },
    confirmation: {
      type: Boolean,
      default: () => false,
    },
    isFullScreen: {
      type: Boolean,
    },
  },
  components: {
    'phone-number-input': PhoneNumberInput,
  },
  data() {
    return {
      form: {
        firstname: '',
        lastname: '',
        email: '',
        phoneNumber: {
          country: '',
          countryCallingCode: '',
          nationalNumber: '',
          number: '',
        },
        quantity: 1,
        restaurant: this.$route.params.restaurantId,
      },
      isTelValid: false,
      active: true,
      showSuccessMessage: false,
      areExtraInfosDisplayed: false,
      loading: false,
      error: null,
    };
  },
  mounted() {
    this.$refs.nameInput.focus();
  },
  computed: {
    isValidPhoneNumber() {
      return this.isTelValid;
    },
    isQuantityValid() {
      return this.form.quantity >= 0;
    },
    isValidEmail() {
      // eslint-disable-next-line no-useless-escape
      return this.form.email ? /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email) : true;
    },
    isFormValid() {
      return this.isValidEmail
             && this.form.lastname.trim()
             && this.isValidPhoneNumber
             && this.isQuantityValid;
    },
    isDisplayed: {
      get() {
        return this.displayed;
      },
      set() {
        this.resetForm();
        this.showSuccessMessage = false;
        clearInterval(this.clock);
        this.$emit('close', false);
      },
    },
  },
  methods: {
    async recaptcha() {
      try {
        // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded();

        // Execute reCAPTCHA with action "login".
        // createAccount landing
        const token = await this.$recaptcha('submitCovidContact');

        // Verify challenge with received token.
        await this.$http.post(`${process.env.VUE_APP_API_URL}/captchas/verify`, { token });
        // Recaptcha success, let's login.
        this.sendContactInfos();
      } catch (error) {
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Une erreur est survenue.',
        });
      }
    },
    async sendContactInfos() {
      const payload = this.form;
      const loading = this.$buefy.loading.open({
        container: null,
      });

      try {
        const res = await this.$http.post(
          `${process.env.VUE_APP_API_URL}/contacts/covid`,
          payload,
        );

        this.$emit('add-contact', res.data);
        if (this.confirmation) {
          this.showSuccessMessage = true;
        } else {
          this.isDisplayed = false;
        }
      } catch (err) {
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Impossible d\'envoyer les informations de contacts.',
        });
      } finally { loading.close(); }
    },
    resetForm() {
      this.form = {
        firstname: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        quantity: 0,
        restaurant: this.$route.params.restaurantId,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .media {
    display: flex;
    align-items: center;
  }
</style>