<template>

  <div class="modal-card" :style="!isFullScreen && { 'max-width': '560px' }">

    <header class="modal-card-head">
      <div class="is-flex is-justify-content-space-between"
           style="width: 100%;">
        <p class="modal-card-title m-0 is-size-6 has-text-weight-semibold"
          style="max-width: 90%;">
          {{ dish && dish.name }}
        </p>
        <button type="button"
                class="delete"
                @click="$emit('close')"/>
      </div>
    </header>

    <section class="modal-card-body">

      <div v-if="dish && dish.image && dish.image.url">
        <b-image  :alt="`${dish.name} picture`"
                  :src="dish.image.url"
                  lazy
                  ratio="16by9"
                  class="m-0 dish-img" />
      </div>

      <div v-if="dish">

        <p class="mt-4 has-text-grey">
          {{ dish.description }}
        </p>

        <div v-if="dish.tags.length > 0"
             style="width: 100%;"
             class="mt-3
                    mb-4
                    is-flex
                    is-flex-wrap-wrap">
          <div v-for="tag in dish.tags"
               :key="tag._id"
               :style="{ color: tag.color }"
               class="tag is-white mb-0 mr-0 pl-0 pr-4">
            <span style="font-size: 1.2em;">{{ tag.label }}</span>
          </div>
        </div>

        <div v-if="dish.allergens.length > 0"
             class="is-flex is-justify-content-flex-end">
          <b-taglist>
            <b-tag type="is-light"
                   v-for="allergen in dish.allergens"
                   :key="allergen._id">
              {{ allergen.label }}
            </b-tag>
          </b-taglist>
        </div>

        <div class="mt-6">

          <!-- Case unit price -->
          <div v-if="!dish.multiPrice">
            <span v-if="dish.price.isSpecialOffer">
                <span class="crossed_out_price mr-4">{{ formatedPrice(dish.price.value) }}</span>
                <b-tag type="is-danger" rounded>
                  {{ formatedPrice(dish.price.specialOffer) }}
                </b-tag>
              </span>
              <span v-else> {{ formatedPrice(dish.price.value) }}</span>
          </div>

          <!-- Case multi prices -->
          <div v-else>
            <ul class="m-0" style="list-style-type: none;">
              <li v-for="price in dish.prices" :key="price._id">
                <span class="mr-4 price_label">{{ price.label }}</span>
                <span v-if="price.isSpecialOffer">
                  <span class="crossed_out_price mr-4">{{ formatedPrice(price.value) }}</span>
                  <b-tag type="is-danger" rounded>
                    {{ formatedPrice(price.specialOffer) }}
                  </b-tag>
                </span>
                <span v-else>{{ formatedPrice(price.value) }}</span>
              </li>
            </ul>
          </div>

        </div>

      </div>

    </section>

    <footer class="modal-card-foot is-flex is-justify-content-flex-end">
      <b-button label="Fermer" @click="$emit('close')" />
    </footer>

  </div>

</template>

<script>
import { CurrencyDirective } from 'vue-currency-input';

export default {
  directives: {
    currency: CurrencyDirective,
  },
  props: {
    dish: {
      type: Object,
    },
    isFullScreen: {
      type: Boolean,
    },
  },
  data() {
    return {
      allergens: [],
      loadingAllergenList: true,
      image: null,
    };
  },
  methods: {
    async fetchAllergens() {
      this.loadingAllergenList = true;
      try {
        const res = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/allergens`,
        );

        this.allergens = res.data;
      } catch (error) {
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Impossible de récupérer la liste des allergènes.',
        });
      } finally { this.loadingAllergenList = false; }
    },
    formatedPrice(value) {
      if (typeof value !== 'number') {
        return value;
      }
      const formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .crossed_out_price {
    text-decoration: line-through;
  }

  ::v-deep .price_label {
    display: inline-block;
    width: 10em;
  }
</style>