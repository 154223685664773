<template>

  <div>

    <!-- If img file -->
    <div v-for="file in images"
         :key="file._id"
         class="mt-4">

      <b-image  :alt="file.metadata.originalname"
                :src="file.url"
                lazy
                class="m-0" />

    </div>

    <!-- If pdf file -->
    <div v-for="file in pdfs"
         :key="file._id"
         class="pdf-container mt-4">

      <div v-if="(file.loadedRatio || 0) < 1" class="pdf-loading">
        loading pdf
      </div>

      <div class="pdf">
        <pdf :src="file.url"
             :page="file.currentPage"
             :rotate="file.rotate"
             @num-pages="file.pageCount = $event"
             @link-clicked="page = $event"
             @progress="file.loadedRatio = $event"
             @loaded="file.show = true"
             @error="onPdfError"></pdf>
      </div>

      <div style="width: 100%;"
           class="is-flex
                  is-justify-content-flex-end
                  is-align-items-center
                  p-2">

        <div>
          <b-button icon-right="rotate-right"
                    type="is-light"
                    class="mr-5"
                    size="is-small"
                    @click="file.rotate = (file.rotate === 270) ? 0 : file.rotate += 90" />
        </div>

        <b-button icon-right="chevron-left"
                  type="is-secondary"
                  class="mr-3"
                  size="is-small"
                  :disabled="file.currentPage <= 1"
                  @click="file.currentPage = file.currentPage > 1 ? file.currentPage - 1 : 1" />

        <div>
          <div class="has-text-grey is-size-6">
            {{ file.currentPage }} / {{ file.pageCount }}
          </div>
        </div>

        <b-button icon-right="chevron-right"
                  type="is-secondary"
                  size="is-small"
                  class="ml-3"
                  :disabled="file.currentPage >= file.pageCount"
                  @click="file.currentPage = file.currentPage < file.pageCount ?
                                              file.currentPage + 1 : file.pageCount"
                  v-if="file.currentPage < file.pageCount" />

      </div>

    </div>

  </div>

</template>

<script>
import pdf from 'vue-pdf';

export default {
  props: {
    menu: {
      type: Object,
    },
  },
  components: {
    pdf,
  },
  data: () => ({
    pdfs: [],
    images: [],
    expendedMeals: [],
    loading: true,
    error: null,
  }),
  created() {
    this.fetchFiles();
  },
  methods: {
    async fetchFiles() {
      try {
        const res = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/restaurants/${this.getRestaurantId()}/menus/${this.getMenuId()}/files`,
        );
        let files = res.data.files || [];

        files = files.map((file) => ({
          ...file,
          url: `${process.env.VUE_APP_API_URL}/restaurants/${this.getRestaurantId()}/menus/${this.getMenuId()}/image/${file.filename}`,
          pageCount: 0,
          currentPage: 1,
          loadedRatio: 0,
          rotate: 0,
          show: false,
        }));

        this.images = files.filter((file) => file.contentType !== 'application/pdf');
        this.pdfs = files.filter((file) => file.contentType === 'application/pdf');
      } catch (err) {
        // eslint-disable-next-line
        console.log(err);
      }
    },
    getRestaurantId() {
      return this.$route.params.restaurantId;
    },
    getMenuId() {
      return this.menu._id;
    },
    onPdfError(err) {
      // eslint-disable-next-line
      console.log('pdf load error', err);
    },
  },
};
</script>

<style lang="scss" scoped>
  .pdf-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    margin-bottom: 2em;

    .pdf-loading {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .pdf {
      width: 100%;
    }
    .pdf-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .grey-text {
        font-size: .8em;
        opacity: 0.8;
      }
    }
    .left-scroll-btn {
      position: absolute;
      top: 45%;
      left: -20px;
      z-index: 5;
    }
    .right-scroll-btn {
      position: absolute;
      top: 45%;
      right: -20px;
      z-index: 5;
    }
  }
</style>