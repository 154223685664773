<template>

  <div class="modal-card" :style="!isFullScreen && { 'max-width': '560px' }">

    <section class="modal-card-body p-0">

      <button type="button"
              class="delete is-large close-btn"
              @click="$emit('close')"/>

      <!-- eslint-disable-next-line max-len -->
      <div v-if="this.mapData.showMap
                  && restaurant.contactInfos.address.location
                  && restaurant.contactInfos.address.location.coordinates.length"
           id="map-container" >
        <l-map ref="myMap"
               v-if="mapData.showMap"
               :zoom="mapData.zoom"
               :center="getLatLng"
               :options="mapData.mapOptions"
               style="height: 330px; z-index: 5;"
               @update:center="centerUpdate"
               @update:zoom="zoomUpdate">

          <l-tile-layer :url="mapData.url"
                        :attribution="mapData.attribution" />

          <l-marker :lat-lng="getLatLng">
            <l-popup>
              <div @click="innerClick">
                {{ restaurant && restaurant.name }}
              </div>
            </l-popup>
          </l-marker>

        </l-map>
      </div>

      <div class="p-4">

        <template v-if="restaurant && restaurant.contactInfos.address.label">
          <h1 class="is-size-4 has-text-grey-darker">Lieu et horaires</h1>

          <div class="pl-4 pr-4 pt-4">
            <p class="has-text-grey">
              <b-icon icon="google-maps" class="mr-1" />
              <a :href="`http://maps.google.com/?q=${restaurant && restaurant.contactInfos.address.label}`"
                target="_blank">
                {{ restaurant && restaurant.contactInfos.address.label }}
              </a>
            </p>
          </div>
        </template>

        <template v-if="restaurant
                        && restaurant.businessHours
                        && restaurant.businessHours.activated">

          <div class="pl-4 pr-4 pt-4">
            <p class="has-text-grey">
              <b-icon icon="clock-outline" class="mr-2" />
              <span class="mb-1">Horaires d'ouverture</span>
              <span class="has-text-grey-light is-size-7">
                (mis à jour {{ formatedDate }})
              </span>
            </p>
          </div>

          <div class="pl-6 pr-4 pt-4 has-text-grey">

            <div v-for="(day, index) in restaurant.businessHours.days"
                 :key="index"
                 class="is-flex mb-1">

              <div style="width: 125px;"
                   :class="{
                     'has-text-weight-semibold': isCurrentDay(index),
                     'has-text-grey-darker': isCurrentDay(index),
                   }">
                {{ hoursLabels[day.label] }}
              </div>

              <div v-if="day.hours.length > 0 && day.open"
                   class="has-text-grey"
                   :class="{
                     'has-text-weight-semibold': isCurrentDay(index),
                     'has-text-grey-darker': isCurrentDay(index),
                   }">
                <p v-for="(hour, idx) in day.hours"
                   :key="idx"
                   class="m-0">
                  {{ formatedBusinessHour(hour.start_time) }}
                  -
                  {{ formatedBusinessHour(hour.end_time) }}
                </p>
              </div>

              <div v-else-if="day.open"
                   :class="{
                     'has-text-weight-semibold': isCurrentDay(index),
                     'has-text-grey-darker': isCurrentDay(index),
                   }">
                <span>Ouvert</span>
              </div>

              <div v-else
                   :class="{
                     'has-text-weight-semibold': isCurrentDay(index),
                     'has-text-grey-darker': isCurrentDay(index),
                   }">
                <span>Fermé</span>
              </div>

            </div>

          </div>

        </template>

      </div>

      <div class="p-4">

        <h1 class="is-size-4 has-text-grey-darker">Besoin d'aide ?</h1>

        <div class="p-4">
          <p class="has-text-weight-semibold">Des questions sur les allergènes ?</p>
          <p class="has-text-grey">
            Demander au restaurant quels sont ses ingrédients et méthodes de préparation.
          </p>
        </div>

        <div class="pl-4 pr-4 has-text-grey">

          <div>
            <p>
              <b-icon icon="phone-outline" size="is-small" />
              Appelez {{ restaurant && restaurant.name }} au
              <a :href="`tel:${ restaurant && restaurant.contactInfos.phoneNumber.number}`">
                {{ restaurant && restaurant.contactInfos.phoneNumber.number }}
              </a>
            </p>
          </div>

          <div class="mt-4">
            <a :href="`tel:${ restaurant && restaurant.contactInfos.phoneNumber.number}`">
              <b-button type="is-light"
                        expanded
                        icon-left="phone-outgoing"
                        class="is-link">
                Appeler
              </b-button>
            </a>
          </div>

        </div>

      </div>

      <div class="p-4 mb-4" v-if="restaurant && restaurant.isTakeawayActivated">
        <h1 class="is-size-4 has-text-grey-darker">Services</h1>
        <ul class="is-size-6 has-text-grey m-0 pl-4 pr-4" style="list-style-type: none;">
          <li class="has-text-secondary has-text-weight-medium mt-4"
              v-if="restaurant && restaurant.isTakeawayActivated">
            <b-icon icon='check' class="mr-2" />
            <span>Ouvert à la vente à emporter</span>
          </li>
        </ul>
      </div>

    </section>

  </div>

</template>

<script>
import { latLng } from 'leaflet';
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
} from 'vue2-leaflet';
import moment from 'moment';

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  props: {
    restaurant: {
      type: Object,
    },
    isFullScreen: {
      type: Boolean,
    },
  },
  created() {
    if (this.restaurant.contactInfos?.address?.location?.coordinates) {
      this.mapData.showMap = true;
    }
  },
  data() {
    return {
      mapData: {
        zoom: 17.5,
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        currentZoom: 11.5,
        currentCenter: [48.866667, 2.333333],
        mapOptions: {
          zoomSnap: 0.5,
          dragging: false,
          zoomControl: false,
          scrollWheelZoom: false,
          touchZoom: false,
          doubleClickZoom: false,
          boxZoom: false,
          keyboard: false,
        },
        showMap: false,
      },
      hoursLabels: {
        LUN: 'Lundi',
        MAR: 'Mardi',
        MER: 'Mercredi',
        JEU: 'Jeudi',
        VEN: 'Vendredi',
        SAM: 'Samedi',
        DIM: 'Dimanche',
      },
      loading: true,
      error: null,
    };
  },
  computed: {
    getLatLng() {
      const coordinates = this.restaurant ? (
        this.restaurant.contactInfos?.address?.location?.coordinates
      ) : (
        [48.866667, 2.333333] // default to Paris
      );
      const lat = coordinates[1];
      const lng = coordinates[0];

      return latLng(lat, lng);
    },
    formatedDate() {
      const nativeDate = this.restaurant?.businessHours?.updatedAt;
      const lastUpdate = moment(nativeDate);

      return lastUpdate.fromNow();
    },
  },
  methods: {
    formatedBusinessHour(date) {
      return moment(date).format('HH:mm');
    },
    isCurrentDay(index) {
      return moment().isoWeekday() === index + 1;
    },
    zoomUpdate(zoom) {
      this.mapData.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.mapData.currentCenter = center;
    },
    innerClick() {},
  },
};
</script>

<style lang="scss" scoped>
  .close-btn {
    position: absolute;
    z-index: 1000;
    top: 20px;
    right: 20px;
  }
</style>