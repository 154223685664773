<template>

  <b-notification
    :type="type"
    :closable="closable"
    indefinite
    class="p-5"
  >
    {{ message }}
  </b-notification>

</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: () => 'is-info is-light',
    },
    message: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    closable: false,
  }),
};
</script>